import React from 'react';
import './contact.css'
import '../App.css';


const contact = () => {
  return (
    <div className="contact">
    <div className="contact-content">
        <main>
        <h1>Contact Me</h1>
        <h3 className='slide-in'>Feel free to get in touch with me using the contact information below:</h3>
        <div className="contact-info">
          <p>Phone: +1234567890</p>
          <p>Email: robert.antony@grappling.sk</p>
          <a className='slide-in' href="/pdfs/cv2.pdf" target="_blank" rel="noopener noreferrer">Download CV</a>
        </div>    
        </main>
    </div>
    </div>
  );
};

export default contact;
