import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MainContent from './components/MainContent';
import About from './components/about';
import Courses from './components/Courses';
import Projects from './components/Projects';
import Contact from './components/contact';
import Footer from './components/Footer';
import './App.css';

function App() {

  
  return (
    <Router>
      <div>
      <Header />
  
        <Routes> 
          <Route path="/" element={<MainContent />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

