import React from 'react';
import './Projects.css';
import '../App.css';

const Projects = () => {
  return (
    <div className="projects">
      <div className="projects-background">
      <main className='slide-in'>
        <h1>My Projects</h1>
        <section className="project">
          <h2>Grappling.sk</h2>
          <p>
            This website is built using React. Grappling.sk is dedicated to mixed martial arts and Brazilian jiu-jitsu.
          </p>
          <a href="https://www.grappling.sk" target="_blank" rel="noopener noreferrer">
            Visit Grappling.sk
          </a>
        </section>

        <section className="project">
          <h2>GrapplingNotes.eu</h2>
          <p>
            GrapplingNotes.eu is developed with PHP and uses PHPMyAdmin for the database management.
          </p>
          <a href="https://www.grapplingnotes.eu" target="_blank" rel="noopener noreferrer">
            Visit GrapplingNotes.eu
          </a>
        </section>

        <section className="project">
          <h2>Add Favorite Movies</h2>
          <p>
            These two websites are created using React and use Firebase for database management.
          </p>
          <a href="https://add-favourite-movies.netlify.app/" target="_blank" rel="noopener noreferrer">
            Visit Add Favorite Movies
          </a>

          <a href="https://add-favourite-movie.netlify.app/" target="_blank" rel="noopener noreferrer">
            Visit Add Favourite Movie
          </a>
        </section>
      </main>
    </div>
    </div>
  );
};

export default Projects;
