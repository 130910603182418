import React from 'react';
import '../App.css';

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 Robert Antony</p>
    </footer>
  );
};

export default Footer;
