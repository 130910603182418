import React from 'react';
import './Courses.css';
import '../App.css';


const Courses = () => {
  return (
    <div className="courses">
      <div className="courses-background">
        <main>
          <h1>Courses</h1>


          <section className="course">
            <h2>Basics of Online Marketing</h2>
            <p>Google Digital Garage | Aug 2022 - Sep 2022</p>
            <a href="/pdfs/digital_garage.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>Fundamentals of Programming <br></br>and Object-Oriented
                Programming</h2>
            <p>
              Completed Object oriented programing course on Skillmea.
            </p>
            <a href="/pdfs/oop.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>Html - Css - JavaScript</h2>
            <p>
              Completed Web developer course on Skillmea.
            </p>
            <a href="/pdfs/html_css_javascript.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>React</h2>
            <p>
              Completed React course on Skillmea.
            </p>
            <a href="/pdfs/react.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>SQL Databases - MySQL, SQLite</h2>
            <p>
              Completed SQL databases course on Skillmea.
            </p>
            <a href="/pdfs/sql_databazy.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>PLSQL</h2>
            <p>
              Completed PLSQL course on Vita.sk.
            </p>
            <a href="/pdfs/plsql.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>WordPress</h2>
            <p>
              Completed WordPress course on Skillmea.
            </p>
            <a href="/pdfs/wordpress.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>Git and GitHub</h2>
            <p>
              Completed Git and GitHub course on Skillmea.
            </p>
            <a href="/pdfs/git.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>Scrum</h2>
            <p>
              Completed Scrum course on Skillmea.
            </p>
            <a href="/pdfs/scrum.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>Microsoft Excel</h2>
            <p>
              Completed Microsoft Excel course on Skillmea.
            </p>
            <a href="/pdfs/excel.pdf" target="_blank" rel="noopener noreferrer">
              View Certificate
            </a>
          </section>

          <section className="course">
            <h2>Web Developer, Web Editor</h2>
            <p>Macrosoft s. r. o. | Jul 2022 - Aug 2022</p>
          </section>

          <section className="course">
            <h2>SEO Optimization for Search Engines</h2>
            <p>Macrosoft s. r. o. | Jul 2022 - Aug 2022</p>
          </section>

          <section className="course">
            <h2>ChatGPT Intensive, DALL-E 3, prompt engineering</h2>
            <p>
              Completed ChatGPT course on Udemy.
            </p>
          </section>

          <section className="course">
            <h2>Fundamentals of Networking</h2>
            <p>
              Completed Fundamentals of Networking course on Udemy.
            </p>
          </section>
          
          <section className="course">
            <h2>PHP from beginner to advanced</h2>
            <p>
              Completed PHP course on David Setek Youtube chanell.
            </p>
          </section>
          
          <section className="course">
            <h2>SQL for programmers and managers</h2>
            <p>
              Completed SQL for programmers and managers course on David Setek Youtube chanell.
            </p>
          </section>
          
          
          <section className="course">
            <h2>Hacking and Cybersecurity</h2>
            <p>
              Completed Hacking and Cybersecurity course on David Setek Youtube chanell.
            </p>
          </section>

          <section className="course">
            <h2>GraphQL</h2>
            <p>
              Completed GraphQL course on Skillmea.
            </p>
          </section>

        </main>
      </div>
    </div>
  );
};

export default Courses;
