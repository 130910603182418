import React from 'react';
import Quote from './Quote';
import './MainContent.css';
import '../App.css';


const MainContent = () => {
  return (
    <div className="home">
      <main>
        <h1 className='slide-in'>Hi, Im Robert Antony.<br></br>Welcome to My Portfolio</h1>  
      </main>
      <Quote />
    </div>
  );
};

export default MainContent;
