import React from 'react';
import '../App.css';
import './about.css';

const About = () => {
  return (
    <div className="about">
      <main>
        <h1 className='slide-in'>Transitioning from Martial Arts to IT: A Journey of Passion and Learning</h1>
        <section>
          <p className='slide-in'>
            Ambitious IT enthusiast transitioning from a career as a mixed martial arts trainer and one of the head coaches of a Brazilian Jiu Jitsu school. Now shifting my focus to the IT field, I have a foundational understanding of HTML, CSS, JavaScript, and React, and I am continually improving my skills in these areas. I am also developing a deeper understanding of database management, SEO, and digital marketing.
          </p>
          <p className='slide-in'>
            In the course of self-learning, I've worked on several personal projects involving both relational and non-relational databases. These projects have given me practical exposure to tools like Firebase, MongoDB, and Oracle, improving my database management skills. I am excited to apply and expand this knowledge in professional settings.
          </p>
          <p className='slide-in'>
            As I embark on this new career path, I am enthusiastic about growing my expertise in the IT world and making meaningful contributions to a dynamic work environment. Despite being at the beginning of my IT journey, my passion for continuous learning, and the same dedication that drives me to the top of the highest ranks in combat sport, fuel my desire for knowledge in this new realm. Outside of work, I'm transitioning my lifestyle to balance my love for sports with my newfound interest in technology and coding.
          </p>
        </section>
      </main>
    </div>
  );
};

export default About;
