import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header>
      <nav>
        <ul>
        <li><NavLink to="/" exact activeClassName="active" className="nav-link">Home</NavLink></li>
          <li><NavLink to="/about" activeClassName="active" className="nav-link">About</NavLink></li>
          <li><NavLink to="/Projects" activeClassName="active" className="nav-link">Projects</NavLink></li>
          <li><NavLink to="/Courses" activeClassName="active" className="nav-link">Courses</NavLink></li>
          <li><NavLink to="/contact" activeClassName="active" className="nav-link">Contact</NavLink></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
