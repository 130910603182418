// quotes.js
const quotes = [
    "Your future is created by what you do today, not tomorrow.",
    "The only way to do great work is to love what you do.",
    "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "Consistent effort today brings extraordinary achievements tomorrow.",
    "Focus on the journey; each step you take brings you closer to your goals."

    // Add more quotes as needed
  ];
  
  export default quotes;
  