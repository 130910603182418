// Quote.js
import React from 'react';
import quotes from '../Quotes';

const Quote = () => {
  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

  return (
    <div>
      <blockquote>{randomQuote}</blockquote>
    </div>
  );
};

export default Quote;
